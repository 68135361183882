import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PlanningDataService } from "../core/services/planning-data.service";
import { Project } from "../core/models/projectModel";

import { Units } from "./units";
import { moduleTypes, ModuleType } from "./moduleTypes";

@Component({
  styleUrls: ["./panel-calculation-page.component.scss"],
  selector: "cm-panel-calculation-page",
  templateUrl: "./panel-calculation-page.component.html",
})
export class PanelCalculationPageComponent implements OnInit {
  @Input() sparrenBreite?: number;
  @Input() sparrenAbstand?: number;
  @Input() ersterSparrenAbstand?: number;
  @Input() deckBreite?: number;
  @Input() deckLaenge?: number;

  activeUnit: Units = Units.Tiles;
  project: Project;
  allModules: ModuleType[];
  selectedModule: ModuleType;
  zipCode?: string;

  horizontalTileCount?: number;
  verticalTileCount?: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected planningDataService: PlanningDataService
  ) {}

  async ngOnInit() {
    this.route.data.subscribe(async (data) => {
      this.project = data.project;

      this.resetInputs();

      this.horizontalTileCount = this.project.roofs[0].horizontalTileCount;
      this.verticalTileCount = this.project.roofs[0].verticalTileCount;

      this.selectedModule = moduleTypes[moduleTypes.length - 1];

      this.allModules = moduleTypes.map((type) => ({
        ...type,
        active: type === this.selectedModule,
      }));
      this.zipCode = this.project.zipCode;
    });
  }

  hasInputChanges() {
    return (
      this.sparrenBreite !== this.project.rafterWidth ||
      this.sparrenAbstand !== this.project.rafterDistance ||
      this.ersterSparrenAbstand !== this.project.firstRafterDistance ||
      this.deckBreite !== this.project.tileWidth ||
      this.deckLaenge !== this.project.tileHeight
    );
  }

  resetInputs() {
    this.sparrenBreite = this.project.rafterWidth;
    this.sparrenAbstand = this.project.rafterDistance;
    this.ersterSparrenAbstand = this.project.firstRafterDistance;
    this.deckBreite = this.project.tileWidth;
    this.deckLaenge = this.project.tileHeight;
  }

  persistInputs() {
    this.project.rafterWidth = this.sparrenBreite;
    this.project.rafterDistance = this.sparrenAbstand;
    this.project.firstRafterDistance = this.ersterSparrenAbstand;
    this.project.tileWidth = this.deckBreite;
    this.project.tileHeight = this.deckLaenge;

    this.planningDataService.persist(this.project);
  }

  onActiveUnitChange(unit: Units) {
    this.activeUnit = unit;
  }

  onSelectedModuleChange(event: any) {
    this.selectedModule = this.allModules[event.target.selectedIndex];
  }
}
