import { PlanningDataService } from "./../core/services/planning-data.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import _forEach from "lodash/forEach";
import { MsalService } from "@azure/msal-angular";
import { Project } from "../core/models/projectModel";
import { environment as env } from "../../environments/environment";

@Component({
  styleUrls: ["./planning-data-page.component.css"],
  selector: "cm-planning-data-page",
  templateUrl: "./planning-data-page.component.html",
})
export class PlanningDataPageComponent implements OnInit, OnDestroy {
  visibilityMap: any;
  pathParamsSubscription: Subscription;
  orderNumber: string;
  projectId: string;
  userInfoDisplayed: boolean;
  userName: string;
  userEmail: string;
  project: Project;
  linkToSalesforce: string;
  linkToSunroof: string;
  linkToIpa: string;

  constructor(
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private planningDataService: PlanningDataService,
    private ngZone: NgZone
  ) {}

  async ngOnInit() {
    this.route.data.subscribe(async (data) => {
      let visibleComponentKey = this.route.snapshot.data["visible"];
      if (!visibleComponentKey) {
        visibleComponentKey = "MAP_SELECTION";
      }
      this.planningDataService.setComponentVisibility(
        visibleComponentKey,
        true
      );
      this.visibilityMap = this.planningDataService.visibilityMap;
      this.project = data.project;
      this.linkToSalesforce = `${env.salesforceUrl}/${this.project.opportunityId}`;
      this.orderNumber = this.route.snapshot.paramMap.get("orderNumber");
      this.linkToSunroof = `${env.sunroofUrl}/orders/${this.orderNumber}`
      this.linkToIpa = `${env.ipaUrl}/protocols/${this.orderNumber}`
      this.projectId = this.route.snapshot.paramMap.get("projectId");

      if (this.project.roofs.length < 1) {
        this.project.roofs.push({} as any);
      }

      const authSvc: any = this.authService;
      const account: any = authSvc.instance.getAllAccounts()[0];
      this.userName = account.name;
      this.userEmail = account.userName;

      this.planningDataService.onComponentVisibilityChanged.subscribe(
        (visibilityMap) => {
          if (visibilityMap["MAP_SELECTION"]) {
            this.visibilityMap = visibilityMap;
          }
        }
      );

      this.pathParamsSubscription = this.route.queryParams.subscribe(
        (params) => {
          if (params["client"]) {
          }
        }
      );
    });
  }

  ngOnDestroy() {
    if (this.pathParamsSubscription) {
      this.pathParamsSubscription.unsubscribe();
    }
  }

  goToLink(innerLink, visibleSection) {
    this.router.navigate(
      [`/projects/${this.project.orderNumber}/${this.project.id}/${innerLink}`],
      { queryParamsHandling: "merge" }
    );
  }

  toggleUserInfo() {
    this.userInfoDisplayed = !this.userInfoDisplayed;
  }

  logout() {
    this.authService.logout();
  }
}
